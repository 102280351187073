<template>
  <div :class="popupClasses()">
    <img
      src="../../assets/images/icons/small_close_icon.svg"
      alt="Notification close icon"
      v-on:click="closePopup()"
    />

    <h3>{{ this.title }}</h3>
    <p>{{ this.message }}</p>
  </div>
</template>

<script>
import { EventBus } from "@/utils/EventBus.js";
export default {
  name: "NotificationPopup",
  data() {
    return {
      title: "",
      message: "",
      showPopup: false,
      success: true,
    };
  },
  mounted() {
    EventBus.$on("show-notification", this.onEventOpen);
  },
  destroyed() {
    EventBus.$off("show-notification", this.onEventOpen);
  },
  methods: {
    popupClasses() {
      return {
        "notification-popup": true,
        "notification-popup-show": this.showPopup,
        "notification-popup-show-success": this.success,
        "notification-popup-show-error": !this.success,
      };
    },
    onEventOpen(data) {
      this.openPopup(data.title, data.message, data.success, data.milliseconds);
    },
    openPopup(title, message, success, milliseconds) {
      this.title = title;
      this.message = message;
      this.success = success;
      this.showPopup = true;

      setTimeout(() => {
        this.showPopup = false;
      }, milliseconds);
    },
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>

<style lang="scss">
.notification-popup {
  opacity: 0;
  position: absolute;
  right: -400px;
  top: 25px;
  width: 400px;
  min-height: 72px;
  padding: 19px 15px 15px 15px;
  z-index: 10;
  background-color: white;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(222, 222, 222, 1);
  -moz-box-shadow: 0px 0px 40px 0px rgba(222, 222, 222, 1);
  box-shadow: 0px 0px 40px 0px rgba(222, 222, 222, 1);

  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;

  h3,
  p {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  h3 {
    font-weight: 550;
    margin-bottom: 5px;
  }

  img {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 8px;
    height: 10px;
  }

  &-show {
    opacity: 1;
    transform: translate(-400px, 0px);
    &-success {
      border-left: 4px solid #50ae22;
    }
    &-error {
      border-left: 4px solid #ff1e1e;
    }
  }
}
</style>
